import { createContext, useReducer } from "react";
import CartContext from "./cartContext";

const defaultCartState = {
  items: [],
  categories: {},
  menu: [],
  resturantFlag: "",
  path: {},
  allMenu: [],
  goBack: false,
  resturant: {},
  checkout: false,
  isCartVisible: false,
  loading: false,
  showItems: [],
  onScroll: "",
  isDarkTheme: false,
};

const cartReducer = (state: any, action: any) => {
  if (action.type == "ADD") {
    const updatedItems = action.item.items;

    return {
      items: updatedItems,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_QUANTITY") {
    let tempCart = [...state.items];
    let newItem = action?.item?.item;
    const existIndex = tempCart.findIndex(
      (item) => item.itemName === newItem.itemName
    );

    if (existIndex != -1) {
      let tempList: any = [...tempCart];

      tempList[existIndex] = {
        ...tempList[existIndex],
        quantity:
          action?.item?.action === "increment"
            ? tempList[existIndex].quantity + 1
            : tempList[existIndex].quantity - 1,
      };

      if (tempList[existIndex].quantity === 0) {
        tempList.splice(existIndex, 1); // Remove the item from the cart
      }

      tempCart = tempList;
    }

    return {
      items: tempCart,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_CATEGORY") {
    const category = action.item;

    return {
      items: state.items,
      categories: category,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_MENU") {
    const menus = action.item.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: menus,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_RESTURANT_FLAG") {
    const restFlag = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: restFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_PATH") {
    const tempPath = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: tempPath,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_ALL_MENU") {
    const tempMenu = action.item.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: tempMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "GO_BACK") {
    const temp = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: temp,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_RESTURANT") {
    const rest = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: rest,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }

  if (action.type == "CHECKOUT") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: check,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_CART") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: check,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "LOADING") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: check,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "MODAL_ITEMS") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: check,
      onScroll: state.onScroll,
      isDarkTheme: state.isDarkTheme,
    };
  }

  if (action.type == "HANDLE_SCROLL") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: check,
      isDarkTheme: state.isDarkTheme,
    };
  }
  if (action.type == "HANDLE_THEME") {
    const check = action.item;

    return {
      items: state.items,
      categories: state.categories,
      menu: state.menu,
      resturantFlag: state.resturantFlag,
      path: state.path,
      allMenu: state.allMenu,
      goBack: state.goBack,
      resturant: state.resturant,
      checkout: state.checkout,
      isCartVisible: state.isCartVisible,
      loading: state.loading,
      showItems: state.showItems,
      onScroll: state.onScroll,
      isDarkTheme: check,
    };
  }

  return defaultCartState;
};

const CartProvider = (props: any) => {
  const [cartState, dispatchCartActions] = useReducer(
    cartReducer,
    defaultCartState
  );

  const addItemHandler = (item: any) => {
    dispatchCartActions({ type: "ADD", item: item });
  };
  const handleQuantity = (item: any) => {
    dispatchCartActions({ type: "HANDLE_QUANTITY", item: item });
  };
  const handleCategory = (item: any) => {
    dispatchCartActions({ type: "HANDLE_CATEGORY", item: item });
  };
  const handleMenu = (item: any) => {
    dispatchCartActions({ type: "HANDLE_MENU", item: item });
  };
  const handleResturantFlag = (item: any) => {
    dispatchCartActions({ type: "HANDLE_RESTURANT_FLAG", item: item });
  };
  const handlePath = (item: any) => {
    dispatchCartActions({ type: "HANDLE_PATH", item: item });
  };
  const handleAllMenu = (item: any) => {
    dispatchCartActions({ type: "HANDLE_ALL_MENU", item: item });
  };
  const handleBack = (item: any) => {
    dispatchCartActions({ type: "GO_BACK", item: item });
  };
  const handleResturant = (item: any) => {
    dispatchCartActions({ type: "HANDLE_RESTURANT", item: item });
  };
  const handleCheckout = (item: any) => {
    dispatchCartActions({ type: "CHECKOUT", item: item });
  };
  const handleCart = (item: any) => {
    dispatchCartActions({ type: "HANDLE_CART", item: item });
  };
  const handleLoading = (item: any) => {
    dispatchCartActions({ type: "LOADING", item: item });
  };
  const handleShowItems = (item: any) => {
    dispatchCartActions({ type: "MODAL_ITEMS", item: item });
  };
  const handleScroll = (item: any) => {
    dispatchCartActions({ type: "HANDLE_SCROLL", item: item });
  };
  const handleTheme = (item: any) => {
    dispatchCartActions({ type: "HANDLE_THEME", item: item });
  };
  const cartContext = {
    items: cartState.items,
    categories: cartState.categories,
    menu: cartState.menu,
    resturantFlag: cartState.resturantFlag,
    path: cartState.path,
    allMenu: cartState.allMenu,
    goBack: cartState.goBack,
    resturant: cartState.resturant,
    checkout: cartState.checkout,
    isCartVisible: cartState.isCartVisible,
    loading: cartState.loading,
    showItems: cartState.showItems,
    onScroll: cartState.onScroll,
    isDarkTheme: cartState.isDarkTheme,
    setDarkTheme: handleTheme,
    handleOnScroll: handleScroll,
    setShowItems: handleShowItems,
    setLoading: handleLoading,
    handleCart: handleCart,
    setCheckout: handleCheckout,
    setResturant: handleResturant,
    setGoBack: handleBack,
    setAllMenu: handleAllMenu,
    setPath: handlePath,
    setResturantFlag: handleResturantFlag,
    addItem: addItemHandler,
    handleQuantity: handleQuantity,
    setCategories: handleCategory,
    setMenu: handleMenu,
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home_cartDisplay__bOfBX {\n  min-width: 10vw;\n  display: none;\n}\n.home_mainGrid__lvgER {\n  margin: -10px;\n}\n\n@media screen and (min-width: 992px) {\n  .home_cartDisplay__bOfBX {\n    min-width: 10vw;\n    display: block;\n  }\n}\n.home_cartModalDisplay__Qro\\+r {\n  min-width: 20vw;\n  display: none;\n}\n@media screen and (width: 0px) {\n  .home_cartModalDisplay__Qro\\+r {\n    min-width: 20vw;\n    display: block;\n  }\n}\n.home_avatar__\\+pErR {\n  max-height: 300px;\n  width: \"auto\";\n  /* position: absolute;\n  bottom: 0; */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/HomePage/home.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;IACf,cAAc;EAChB;AACF;AACA;EACE,eAAe;EACf,aAAa;AACf;AACA;EACE;IACE,eAAe;IACf,cAAc;EAChB;AACF;AACA;EACE,iBAAiB;EACjB,aAAa;EACb;cACY;AACd","sourcesContent":[".cartDisplay {\n  min-width: 10vw;\n  display: none;\n}\n.mainGrid {\n  margin: -10px;\n}\n\n@media screen and (min-width: 992px) {\n  .cartDisplay {\n    min-width: 10vw;\n    display: block;\n  }\n}\n.cartModalDisplay {\n  min-width: 20vw;\n  display: none;\n}\n@media screen and (width: 0px) {\n  .cartModalDisplay {\n    min-width: 20vw;\n    display: block;\n  }\n}\n.avatar {\n  max-height: 300px;\n  width: \"auto\";\n  /* position: absolute;\n  bottom: 0; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartDisplay": "home_cartDisplay__bOfBX",
	"mainGrid": "home_mainGrid__lvgER",
	"cartModalDisplay": "home_cartModalDisplay__Qro+r",
	"avatar": "home_avatar__+pErR"
};
export default ___CSS_LOADER_EXPORT___;

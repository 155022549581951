import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { chatApi } from "../../services/chatApi";
import style from "./style.module.css";
import { micOffSharp, send, mic } from "ionicons/icons";
import CartContext from "../../context/cartContext";
import { useHistory, useLocation } from "react-router-dom";
import getCurrentResturant from "../../services/getResturant";
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { isPlatform } from "@ionic/react";
import { goToResturant } from "../../services/goToResturant";

let tempval = "";

interface Props {
  onStart: () => void;
  onStop: (text: string) => void;
}

const VoiceInputMobile: React.FC<Props> = ({ onStart, onStop }) => {
  console.log("i am b");

  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [scriptLoading, setScriptLoading] = useState<any>(false);
  const [answer, setAnswer] = useState<any>("");
  const [isPaused, setIsPaused] = useState<any>(false);
  const [utterance, setUtterance] = useState<any>(null);
  const [voice, setVoice] = useState<any>(null);
  const [pitch, setPitch] = useState<any>(1);
  const [rate, setRate] = useState<any>(1);
  const [volume, setVolume] = useState<any>(1);
  const [previousRes, setPreviousRes] = useState<any>("");
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [deleteItem, setDeleteItem] = useState([]);
  const [msg, setMsg] = useState("");
  const [changeOrder, setChangeOrder] = useState<any>("");
  const [changeQuantity, setChangeQuantity] = useState<any>("");
  const [userId, setUserId] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined);

  const langArray = [
    { code: "ar-SA", name: "Arabic" },
    { code: "cs-CZ", name: "Czech" },
    { code: "da-DK", name: "Danish" },
    { code: "de-DE", name: "German" },
    { code: "el-GR", name: "Greek" },
    { code: "en-AU", name: "English" },
    { code: "en-GB", name: "English" },
    { code: "en-IE", name: "English" },
    { code: "en-IN", name: "English" },
    { code: "en-US", name: "English" },
    { code: "en-ZA", name: "English" },
    { code: "es-ES", name: "Spanish" },
    { code: "es-MX", name: "Spanish" },
    { code: "fi-FI", name: "Finnish" },
    { code: "fr-CA", name: "French" },
    { code: "fr-FR", name: "French" },
    { code: "he-IL", name: "Hebrew" },
    { code: "hi-IN", name: "Hindi" },
    { code: "hu-HU", name: "Hungarian" },
    { code: "id-ID", name: "Indonesian" },
    { code: "it-IT", name: "Italian" },
    { code: "ja-JP", name: "Japanese" },
    { code: "ko-KR", name: "Korean" },
    { code: "nl-BE", name: "Dutch" },
    { code: "nl-NL", name: "Dutch" },
    { code: "no-NO", name: "Norwegian" },
    { code: "pl-PL", name: "Polish" },
    { code: "pt-BR", name: "Portuguese" },
    { code: "pt-PT", name: "Portuguese" },
    { code: "ro-RO", name: "Romanian" },
    { code: "ru-RU", name: "Russian" },
    { code: "sk-SK", name: "Slovak" },
    { code: "sv-SE", name: "Swedish" },
    { code: "th-TH", name: "Thai" },
    { code: "tr-TR", name: "Turkish" },
    { code: "zh-CN", name: "Chinese" },
    { code: "zh-HK", name: "Chinese" },
    { code: "zh-TW", name: "Chinese" },
  ];

  const {
    addItem,
    items,
    setMenu,
    setCategories,
    setResturantFlag,
    categories,
    menu,
    path,
    setAllMenu,
    allMenu,
    setPath,
    setGoBack,
    setResturant,
    checkout,
    setCheckout,
    loading,
    setLoading,
    setShowItems,
    handleOnScroll,
    handleCart,
  } = useContext(CartContext);
  let [resturantSlug, setResturantSlug] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isFirstRender) {
      const timestamp = Date.now().toString(36); // Convert current time to base36 string
      const randomString = Math.random().toString(36).substr(2, 5); // Generate random string
      const uniqueId = timestamp + randomString; // Concatenate the timestamp and random string
      setUserId(uniqueId);
    }
  }, [isFirstRender]);

  ///getResturant

  useEffect(() => {
    if (resturantSlug.length < 1 && tempval == "") {
      const currentRoute = window.location.pathname;
      let tempResturantSlug = currentRoute.slice(1);
      tempval = tempResturantSlug;
      setResturantSlug(tempResturantSlug);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      getResturant();
    }
  }, [window.location.pathname]);

  const getResturant = async () => {
    try {
      setLoading(true);

      let currentRoute = window.location.pathname;
      let tempResturantSlug = currentRoute.slice(1);
      let res = await getCurrentResturant(`${tempResturantSlug}`);
      if (res?.status == true) {
        // setResturant({ item: res?.data });
        setMenu({ item: res?.data?.menus });
        setAllMenu({ item: res?.data?.menus });
        setResturant({ resturant: res?.data });

        const mainCategories: any = [];
        const subCategories: any = [];
        res?.data?.categories?.forEach((category: any) => {
          if (category.parent) {
            subCategories.push(category);
          } else {
            mainCategories.push(category);
          }
        });

        setCategories({
          allCategories: res?.data?.categories,
          mainCategories,
          subCategories,
        });
      }
      if (res?.status == false) {
        setResturantFlag("error");
      }
    } catch (error) {
      setResturantFlag("error");
    } finally {
      setLoading(false);
    }
  };

  ///

  const askResturant = async () => {
    setScriptLoading(true);
    handleStop();
    try {
      let currentRoute = window.location.pathname;
      let tempResturantSlug = currentRoute.slice(1);
      let data = await goToResturant({
        message: transcript,
      });

      // let res = await fetch(
      //   "https://0143-182-179-128-119.ngrok-free.app/restuarant",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       message: transcript,
      //     }),
      //   }
      // );
      // let data: any = await res?.json();
      if (data?.restuarantName == null) {
        setAnswer({
          response: "no such restaurant found",
        });
      } else if (data?.restuarantName == "Factory Girl") {
        history.push("/factory-girl");
        setAnswer({
          response: "opening factory girl",
        });
      } else {
        history.push("/mcdonald-s");
        setAnswer({
          response: "opening McDonald's",
        });
      }
    } catch (error) {
      console.log("error in choosing resurant");
    } finally {
      setIsFirstRender(false);
      setScriptLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname == "/") {
      setAnswer({ response: "which resturant you want to go" });
    }
    if (transcript && location.pathname == "/") {
      // history.push("/factory-girl");
      askResturant();
    }
  }, [location, transcript]);
  /////////////speaking part//////////

  const addToCart = (
    name: any,
    flavour: any,
    addons: any,
    quantity: any,
    cart: any
  ) => {
    //1st of all, check if the item exists
    let orderedItem = allMenu.filter(
      (items) => items.title.toLowerCase() == name.toLowerCase()
    );
    if (orderedItem.length > 0) {
      //if array length is equal to 1, it means item exist
      //now check if the item exists in cart or not
      let exist = cart.findIndex(
        (data: any) =>
          data.itemName.toLowerCase() == name.toLowerCase() &&
          data?.flavour?.description?.toLowerCase() == flavour?.toLowerCase() &&
          data?.addons?.name?.toLowerCase() == addons?.toLowerCase()
      );
      if (exist !== -1) {
        // If an existing item was found, increment its quantity
        cart[exist].quantity += parseInt(quantity);
        addItem({ items: cart });
      } else {
        const selectedItem = allMenu.find(
          (item: any) => item?.title?.toLowerCase() === name?.toLowerCase()
        );
        let selectedFlavour = selectedItem?.price.find(
          (item: any) =>
            item?.description?.toLowerCase() == flavour?.toLowerCase()
        );
        let selectedAddon = selectedItem?.addOns.find(
          (item: any) => item?.name?.toLowerCase() == addons?.toLowerCase()
        );

        let totalPrice =
          parseFloat(selectedFlavour?.price) +
          parseFloat(selectedAddon?.price ?? 0);

        addItem({
          items: [
            ...items,
            {
              itemName: name,
              flavour: selectedFlavour ? selectedFlavour : undefined,
              addons: selectedAddon ? selectedAddon : undefined,
              quantity: parseInt(quantity),
              image: selectedItem.image,
              price: totalPrice,
            },
          ],
        });
      }
    } else {
      console.log("data==>", "not exist");
    }
  };

  const apiCall = async () => {
    setScriptLoading(true);
    handleStop();
    try {
      let currentRoute = window.location.pathname;
      let tempResturantSlug = currentRoute.slice(1);
      let data = await chatApi({
        message: transcript,
        user_id: userId,
        new_chat: isFirstRender ? "1" : "0",
        restuarant_name: tempResturantSlug,
      });

      // let res = await fetch("https://0e32-39-55-235-172.ngrok-free.app/menu", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     message: transcript,
      //     user_id: userId,
      //     new_chat: isFirstRender ? "1" : "0",
      //     restuarant_name: tempResturantSlug,
      //   }),
      // });
      // let data: any = await res?.json();

      if (data?.language) {
        let lang = langArray.filter((obj: any, i: any) => {
          let x = obj.name.toLowerCase() == data?.language.toLowerCase();
          return x;
        });
        if (lang.length > 0) {
          setSelectedLanguage(lang[0].code);
        } else {
          setSelectedLanguage("en-US");
        }
      }

      console.log("newdata", data);

      setAnswer(data);

      //placing order <========================
      if (data?.formatted_order) {
        console.log("data?.formatted_order", data?.formatted_order);
        addToCart(
          data?.formatted_order?.itemName,
          data?.formatted_order?.flavour
            ? data?.formatted_order?.flavour
            : undefined,
          data?.formatted_order?.addons
            ? data?.formatted_order?.addons
            : undefined,
          data?.formatted_order?.quantity,
          items
        );
      }

      //navigation handeling <========================

      if (data?.data && data?.data?.length > 0) {
        //searching in categories
        let tempCat = categories?.allCategories.filter(
          (e: any) => e?.name.toLowerCase() === data?.data.toLowerCase()
        );
        if (tempCat.length > 0 && tempCat[0].parent) {
          //it means it is a sub category, search its parent and search its items

          let tempMenu = allMenu.filter(
            (e: any) => e?.category === tempCat[0]?._id
          );

          let tempSub = categories?.mainCategories.filter(
            (e: any) => e?._id === tempCat[0]?.parent
          );

          setPath({
            selectedCategory: tempSub[0],
            selectedSubCategory: tempCat[0],
          });

          setMenu({
            item: tempMenu,
          });
        }
        if (tempCat.length > 0 && !tempCat[0].parent) {
          //it means it is a  maincCategory, set it globally
          const backupCategories = categories;
          let tempSub = categories?.subCategories.filter(
            (e: any) => e?.parent == tempCat[0]._id
          );

          //if no subcategory exists, search items and set

          if (tempSub.length < 1) {
            let tempMenu = allMenu.filter(
              (e: any) => e?.category == tempCat[0]?._id
            );

            setMenu({
              item: tempMenu,
            });
            setPath({
              selectedCategory: tempCat[0],
              selectedSubCategory: "noSub",
            });

            /////
            setPreviousRes(data);
            setIsFirstRender(false);
            setScriptLoading(false);

            return;
          }
          setPath({
            selectedCategory: tempCat[0],
            selectedSubCategory: "",
          });
          setCategories({
            allCategories: backupCategories.allCategories,
            mainCategories: backupCategories.mainCategories,
            subCategories: tempSub,
          });
        }

        //if it is a menu item then this block will render
        if (tempCat.length > 0 == false) {
          //it means it is an item,
          //searching in items
          let tempItem = allMenu.filter(
            (e: any) => e?.title.toLowerCase() === data?.data.toLowerCase()
          );

          //finding its subcategory

          let tempSub = categories?.subCategories.filter(
            (e: any) => e?._id === tempItem[0]?.category
          );

          //if the tempSub is an empty array, it means this restaurant
          //does not have subcategory, program accordingly.

          //if the tempSub is empty array, "if" statement will run, in other case, 'else' will run

          if (tempSub?.length < 1) {
            //finding its main category
            let tempMain = categories?.mainCategories.filter(
              (e: any) => e?._id === tempItem[0]?.category
            );

            let tempMenu = allMenu.filter(
              (e: any) => e?.category === tempMain[0]?._id
            );

            setPath({
              selectedCategory: tempMain[0],
              selectedSubCategory: "noSub",
            });

            setMenu({
              item: tempMenu,
            });
          } else {
            //finding its main category

            let tempMain = categories?.mainCategories.filter(
              (e: any) => e?._id === tempSub[0]?.parent
            );

            //find its menu items
            let tempMenu = allMenu.filter(
              (e: any) => e?.category === tempSub[0]?._id
            );

            setPath({
              selectedCategory: tempMain[0],
              selectedSubCategory: tempSub[0],
            });

            setMenu({
              item: tempMenu,
            });
          }
        }
      }

      //action handeling <========================

      if (data?.action && data?.action.length > 0) {
        if (data.action == "back") {
          setGoBack(true);
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "exitChat") {
          stopRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "remove") {
          setDeleteItem(data.itemsToRemove);
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "changeQuantity") {
          setChangeQuantity({
            itemToChange: data.itemName,
            quantity: data?.quantityNew,
          });
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "change") {
          setChangeOrder({
            itemToChange: data?.itemToChange,
            replacemnet: data?.replacemnet,
          });
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "checkout") {
          if (items.length < 1) {
            setAnswer({
              response: "Your cart is empty, please make you order first",
            });
          } else {
            setCheckout(true);
          }
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "openItem") {
          setShowItems([data?.itemToOpen]);
          startRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "closeItem") {
          setShowItems([]);
          handleCart(false);
          startRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "scrollUp") {
          handleOnScroll("up");
          startRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "scrollDown") {
          handleOnScroll("down");
          startRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "openCart") {
          handleCart(true);
          startRecording();
        }
      }
      if (data?.action && data?.action.length > 0) {
        if (data.action == "emptyCart") {
          if (items.length < 1) {
            setAnswer({
              response: "You have not ordered anything yet",
            });
          } else {
            addItem({
              items: [],
            });
            setAnswer({
              response: "your order has been canceled",
            });
          }
        }
      }
      setPreviousRes(data);
      setIsFirstRender(false);
      setScriptLoading(false);
    } catch (error) {
      setAnswer({ response: "Please Try again" });
      setScriptLoading(false);
    }
  };

  useEffect(() => {
    if (checkout) {
      addItem({
        items: [],
      });
      setAnswer({
        response:
          "Thank you for your order. We have successfully received it and it is currently being processed. Rest assured, we will have it ready for you shortly",
      });
    }
  }, [checkout]);

  useEffect(() => {
    if (changeOrder?.itemToChange?.length > 0) {
      let itemToBeChanged = items.find(
        (cartItem) =>
          cartItem?.itemName?.toLowerCase() ===
          changeOrder?.itemToChange?.toLowerCase()
      );
      if (itemToBeChanged == undefined) {
        setAnswer({
          response: "item to be replaced is not ordered yet",
        });
        setChangeOrder("");
      }
      if (itemToBeChanged !== undefined) {
        //here we deleted item
        let upatedArray = items.filter((data: any) => data !== itemToBeChanged);

        let itemToBeAdded = allMenu.find(
          (data: any) => data?.title?.toLowerCase() == changeOrder.replacemnet
        );

        addItem({
          items: [
            ...upatedArray,
            {
              itemName: itemToBeAdded.title,
              flavour: undefined,
              addons: undefined,
              image: itemToBeAdded.image,
              quantity: 1,
              price: itemToBeAdded.price[0].price,
            },
          ],
        });
        setAnswer({
          response: "Updated Your Order",
        });
        setChangeOrder("");

        //now we'll find item and then add it
        console.log("changeordered", [...upatedArray, itemToBeAdded]);
      }
    }
  }, [changeOrder]);

  useEffect(() => {
    if (changeQuantity?.itemToChange?.length > 0) {
      let itemToBeChanged = items.find(
        (cartItem) =>
          cartItem?.itemName?.toLowerCase() ===
          changeQuantity?.itemToChange?.toLowerCase()
      );
      if (itemToBeChanged == undefined) {
        setAnswer({
          response: "Item not ordered yet",
        });
        setChangeQuantity("");
      }
      if (itemToBeChanged !== undefined) {
        //here we deleted item
        let upatedArray = items.filter((data: any) => data !== itemToBeChanged);

        let itemToBeAdded = allMenu.find(
          (data: any) =>
            data?.title?.toLowerCase() == changeQuantity?.itemToChange
        );

        addItem({
          items: [
            ...upatedArray,
            {
              itemName: itemToBeAdded.title,
              flavour: undefined,
              addons: undefined,
              image: itemToBeAdded.image,
              quantity: changeQuantity?.quantity,
              price: itemToBeAdded.price[0].price,
            },
          ],
        });
        setAnswer({
          response: "Updated Your Order",
        });
        setChangeOrder("");

        //now we'll find item and then add it
        console.log("changeordered", [...upatedArray, itemToBeAdded]);
      }
    }
  }, [changeQuantity]);

  useEffect(() => {
    if (deleteItem?.length > 0) {
      // Check if all items to be deleted are already ordered
      const orderedItems = deleteItem.filter((item: any) =>
        items.find(
          (cartItem) => cartItem.itemName.toLowerCase() === item.toLowerCase()
        )
      );
      console.log({ orderedItems });

      if (orderedItems?.length > 0) {
        // Remove items from the cart

        const lowercaseArray = deleteItem.map((item: any) =>
          item.toLowerCase()
        );

        const updatedCart = items.filter(
          (item: any) => !lowercaseArray.includes(item.itemName.toLowerCase())
        );
        setAnswer({ response: `${orderedItems.toString()} deleted` });
        // setAnswer(`${orderedItems.toString()} deleted`);
        // Update the cart state or perform any desired action with the updated cart
        addItem({
          items: updatedCart,
        });
        setDeleteItem([]);
      } else {
        setAnswer({
          response: "Some items to be deleted are not yet ordered.",
        });

        // setAnswer("Some items to be deleted are not yet ordered.");
      }
    }
  }, [deleteItem, items]);

  useEffect(() => {
    if (transcript.length > 0 && location.pathname !== "/") {
      stopRecording();
      apiCall();
    }
  }, [transcript]);

  const sendMsg = () => {
    if (msg.length > 1) {
      setTranscript(msg);
      setMsg("");
    }
  };

  useEffect(() => {
    if (answer?.response?.length > 0) {
      speak(answer?.response);
    }
  }, [answer?.response]);

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      try {
        const languages = await TextToSpeech.getSupportedLanguages();
        console.log("Supported Languages:", languages);
      } catch (error) {
        console.error("Error retrieving supported languages:", error);
      }
    };

    fetchSupportedLanguages();
  }, []);

  const speak = async (response: any) => {
    try {
      handleStop();
      await TextToSpeech.speak({
        text: `${response}`,
        lang: selectedLanguage == undefined ? "en-US" : selectedLanguage,
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: "ambient",
      });
      startRecording();
    } catch (error) {
      alert("something went wrong");
    }
  };

  console.log({ haah: isRecording });

  // useEffect(() => {
  //   if (isRecording) {
  //     setTimeout(() => {
  //       setIsRecording(false);
  //       handleStop();
  //     }, 9000);
  //   }
  // }, [isRecording]);

  const handleStop = async () => {
    await TextToSpeech.stop();
  };

  const stopRecording = () => {
    handleStop();
    setIsRecording(false);
    SpeechRecognition.stop();
  };
  console.log({ transcript });

  const startRecording = async () => {
    try {
      handleStop();
      setIsRecording(true);
      setAnswer("");
      setTranscript("");
      let result = await SpeechRecognition.start({
        language: "en-US",
        maxResults: 1,
        prompt: "Welcome to Factory Girl",
        partialResults: false,
        popup: true,
      });
      const speechResult = result?.matches[0];
      setTranscript(speechResult);
      console.log("==>", speechResult);
    } catch (error) {
      console.log("==> 1", error);
    }
  };

  useEffect(() => {
    SpeechRecognition.requestPermissions();
    SpeechRecognition.available();
  }, []);
  return (
    <div className={style.micContainer}>
      {transcript && !answer?.response && (
        <p className={style.msg}>{transcript}</p>
      )}
      <br />
      <br />
      {!scriptLoading && previousRes?.response ? (
        <p className={style.msg}> {previousRes?.response}</p>
      ) : null}

      {/* <p className={style.msg}> {!scriptLoading && previousRes?.response}</p> */}
      {scriptLoading && <IonSpinner color="primary" name="dots" />}
      <div className={style.buttonConatiner}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
          // onClick={sendMsg}
        >
          <input
            disabled={scriptLoading ? true : false}
            onChange={(e) => setMsg(e.target.value)}
            placeholder="Enter Your Order"
            className={style.inputStyle}
            value={msg}
            onKeyDown={(e) => {
              if (e.key === "Enter") sendMsg();
            }}
          />

          <IonIcon
            onClick={sendMsg}
            size="medium"
            slot="icon-only"
            icon={send}
          ></IonIcon>
        </div>
        {!scriptLoading ? (
          <div
            className={style.micButton}
            color="danger"
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isPlatform("ios") && isRecording ? (
              <p style={{ color: "white" }}>Submit</p>
            ) : (
              <IonIcon
                size="large"
                slot="icon-only"
                color="warning"
                icon={isRecording ? mic : micOffSharp}
              ></IonIcon>
            )}

            {/* <p>{isRecording ? "Stop" : "Start"} Listening</p> */}
          </div>
        ) : (
          <div className={style.micButtonDisabled} color="danger">
            <IonIcon
              size="large"
              slot="icon-only"
              color="warning"
              icon={micOffSharp}
            ></IonIcon>
            {/* <p>{isRecording ? "Stop" : "Start"} Listening</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceInputMobile;

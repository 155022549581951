import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
} from "@ionic/react";
import styles from "./home.module.css";
import { useContext, useEffect, useState } from "react";
import { Route } from "react-router";
import Header from "../../components/Header";
import CardsContainer from "../../components/Card/CardsContainer";
import CategoryContainer from "../../components/CategoryContainer";
import Cart from "../../components/Cart";
import { chatApi } from "../../services/chatApi";
import VoiceInput from "../../components/MicComp/VoiceInput";
import CartContext from "../../context/cartContext";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import Example from "../../components/cartModal";
import ItemModal from "../../components/ItemsModal/itemModal";
import { isPlatform } from "@ionic/react";
import VoiceInputMobile from "../../components/MicComp/VoiceInputMobile";

const HomePage: React.FC = () => {
  const [text, setText] = useState("");
  const handleStart = () => {};
  const { resturantFlag } = useContext(CartContext);

  if (resturantFlag == "error") {
    return <Route component={NotFoundPage} />;
  }

  const handleStop = (inputText: string) => {
    setText(inputText);
  };
  return (
    <IonPage>
      <IonContent fullscreen className={styles.mainHome}>
        <IonGrid>
          <IonRow>
            <IonCol
              size-lg="9"
              sizeXl="9"
              size-md="12"
              size-sm="12"
              sizeXs="12"
            >
              <Header />
              <CategoryContainer />
            </IonCol>
            <IonCol size="2.5" sizeXl="3" size-lg="3" size-md="2">
              <div className={styles.cartDisplay}>
                <Cart />
              </div>
              <div className={styles.cartModalDisplay}>
                {/* example is also modal */}
                <Example />
                <ItemModal />
              </div>
            </IonCol>
          </IonRow>
          {isPlatform("mobileweb") ? (
            <VoiceInput onStart={handleStart} onStop={handleStop} />
          ) : isPlatform("android") || isPlatform("ios") ? (
            <VoiceInputMobile onStart={handleStart} onStop={handleStop} />
          ) : (
            <VoiceInput onStart={handleStart} onStop={handleStop} />
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;

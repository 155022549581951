/* App config for apis
 */

const ApiConfig = {
  BASE_URL: "https://menuopenai.obenan.com/",
  BACKEND_URL: "https://menuapi.obenan.com/api/",
  SERVER_URL: "https://menuapi.obenan.com/",
  CHAT: "menu",
  RESTURANT: "restuarant",
  CURRENT_RESTURANT: "restaurant/find-by-slug/",
};

export default ApiConfig;

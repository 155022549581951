import api from "./client";
import ApiConfig from "../config/apiConfig";

export default function getCurrentResturant(id: any) {
  return api(
    ApiConfig.BACKEND_URL + ApiConfig.CURRENT_RESTURANT + id,
    null,
    "get"
  );
}

import { IonContent, IonButton, IonPage } from "@ionic/react";
import styles from "./welcome.module.css";
import { useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import VoiceInput from "../../components/MicComp/VoiceInput";
import { isPlatform } from "@ionic/react";
import VoiceInputMobile from "../../components/MicComp/VoiceInputMobile";
import MobileWebModal from "../../components/MobileWebModal";

const WelcomePage = () => {
  const history = useHistory();
  const goToResturant = () => {
    history.push("/factory-girl");
  };
  const goToMcDonald = () => {
    history.push("/mcdonald-s");
  };
  const [text, setText] = useState("");
  const handleStart = () => {};

  const handleStop = (inputText: string) => {
    setText(inputText);
  };

  return (
    <IonPage>
      <IonContent>
        <div className={styles.row}>
          {isPlatform("mobileweb") ? (
            <div className={styles.mobile}>
              <MobileWebModal />
            </div>
          ) : null}
          <div className={`${styles.block} ${styles.block1}`}>
            <div className={styles.blockContent}>
              <h1>McDonald's</h1>
              <IonButton
                onClick={goToMcDonald}
                className={styles.welcomeBtn}
                size="large"
              >
                Netherlands
              </IonButton>
            </div>
          </div>
          <div className={`${styles.block} ${styles.block1}`}>
            <div className={styles.blockContent}>
              <h1>Factory Girl</h1>
              <IonButton
                className={styles.welcomeBtn}
                onClick={goToResturant}
                size="large"
              >
                AMSTERDAM
              </IonButton>
            </div>
          </div>
        </div>
        {isPlatform("mobileweb") ? null : isPlatform("android") ||
          isPlatform("ios") ? (
          <VoiceInputMobile onStart={handleStart} onStop={handleStop} />
        ) : (
          <VoiceInput onStart={handleStart} onStop={handleStop} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default WelcomePage;

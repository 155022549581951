import { IonHeader, IonIcon, IonToolbar } from "@ionic/react";
import { IonImg } from "@ionic/react";
import logo from "../../assets/images/logo.png";
import FG from "../../assets/images/FG.png";
import FGBRIGHT from "../../assets/images/fglogoBright.png";
import styles from "./header.module.css";
import { cart } from "ionicons/icons";
import { useContext } from "react";
import CartContext from "../../context/cartContext";
import { isPlatform } from "@ionic/react";

const Header: React.FC = () => {
  const { items, resturant, isCartVisible, handleCart, isDarkTheme } =
    useContext(CartContext);
  return (
    <IonHeader className="ion-no-border" collapse="fade">
      <div
        className={isPlatform("ios") ? styles.mainHeaderIos : styles.mainHeader}
      >
        <IonToolbar>
          <img
            slot="start"
            id="opening-modal"
            className={styles.ResturantLogoFG}
            src={isDarkTheme ? FGBRIGHT : FG}
            alt="ResturantLogo"
          />
          <div style={{ textAlign: "center" }}>
            <h3 className={styles.mainHeading}>{resturant?.resturant?.name}</h3>
          </div>
          <div slot="end" className={styles.cartIcon}>
            <div></div>
            {/* <img
            id="opening-modal"
            className={styles.ResturantLogoFG}
            src={isDarkTheme ? FGBRIGHT : FG}
            alt="ResturantLogo"
          /> */}
            <IonIcon
              onClick={() => handleCart(!isCartVisible)}
              className={styles.cartIconStyle}
              icon={cart}
            />
            <p className={styles.cartIconStyleBadge}>{items.length}</p>
          </div>
        </IonToolbar>
      </div>
    </IonHeader>
  );
};

export default Header;

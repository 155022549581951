import { useContext, useEffect, useRef, useState } from "react";
import {
  createAnimation,
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonList,
  IonLabel,
  IonImg,
} from "@ionic/react";
import CartContext from "../../context/cartContext";
import CartCard from "../CartCard";
import animationData from "../../animation/cart.json";
import styles from "./style.module.css";

function Example() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const modal = useRef<HTMLIonModalElement>(null);
  const [countDownTime, setCountDownTime] = useState(20 * 60 * 1000);
  const {
    items,
    resturant,
    checkout,
    isCartVisible,
    handleCart,
    setCheckout,
    addItem,
  } = useContext(CartContext);

  function dismiss() {
    modal.current?.dismiss();
    handleCart(false);
  }
  function showModal() {
    modal.current?.present();
    handleCart(true);
  }

  useEffect(() => {
    if (isCartVisible) {
      showModal();
    } else if (!isCartVisible) {
      dismiss();
    }
  }, [isCartVisible]);

  const emptyCart = () => {
    addItem({
      items: [],
    });
  };

  useEffect(() => {
    if (checkout) {
      // Update the countdown every second
      const countdownInterval = setInterval(() => {
        // Subtract one second from the countdown time
        setCountDownTime((prevTime) => prevTime - 1000);
      }, 1000);

      // Clear the interval when the component unmounts
      return () => clearInterval(countdownInterval);
    }
  }, [checkout]);

  const minutes: any = Math.floor(countDownTime / 60000);
  const seconds: any = ((countDownTime % 60000) / 1000).toFixed(0);

  let totalItems = items.length;
  let combinedQuantity = items.reduce(
    (total, order) => total + order.quantity,
    0
  );
  const totalAmount = items
    .reduce((totalPrice, order) => totalPrice + order.quantity * order.price, 0)
    .toFixed(2);

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;
    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  return (
    <IonModal
      className={styles.modalCenter}
      id="example-modal"
      ref={modal}
      trigger="open-modal"
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
    >
      <IonContent>
        <IonToolbar>
          <IonTitle>Cart</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={dismiss}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonList>
          <div>
            {/* Map over the userArrays array */}
            {items.map((item, index) => (
              <div className={styles.cardContainerCartModal} key={index}>
                {/* Render the current item's details */}
                <CartCard
                  data={item}
                  name={item?.itemName}
                  image={item?.image}
                  price={item.price}
                  quantity={item?.quantity}
                  selectedAdone={item?.addons?.name}
                  selectedSize={item?.flavour?.description}
                  user={item?.user}
                  serialNo={item?.serialNo}
                />
              </div>
            ))}
          </div>
          {items.length > 0 ? (
            <div>
              <div className={styles.detailsCardModal}>
                <div className={styles.detailsItem}>
                  <IonLabel>Subtotal</IonLabel>
                  <IonLabel>
                    {resturant?.resturant?.currencySymbol}
                    {totalAmount}
                  </IonLabel>
                </div>
                <div className={styles.detailsItem}>
                  <IonLabel>Discount sales</IonLabel>
                  <IonLabel>{resturant?.currencySymbol}0</IonLabel>
                </div>
                <div className={styles.detailsItem}>
                  <IonLabel>Total sales tax</IonLabel>
                  <IonLabel>{resturant?.currencySymbol}0</IonLabel>
                </div>
                <div className={styles.detailsItem}>
                  <h4>Total</h4>
                  <h4>
                    {resturant?.resturant?.currencySymbol}
                    {totalAmount}
                  </h4>
                </div>
              </div>
              <p style={{ textAlign: "center" }}>
                Your cart currently includes {items?.length}{" "}
                {totalItems > 1 ? `items` : `item`}, with a combined quantity of{" "}
                {combinedQuantity} and a total cost of
                {resturant?.resturant?.currencySymbol}
                {totalAmount}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <IonButton color="light" shape="round" onClick={emptyCart}>
                  Cancel
                </IonButton>

                <IonButton
                  shape="round"
                  className={styles.addBtnn}
                  onClick={() => {
                    if (items.length > 0) {
                      setCheckout(true);
                    }
                  }}
                  id="example-modal"
                  // className="confirmBtn"
                  // color="danger"
                >
                  Confirm Order
                </IonButton>
              </div>
            </div>
          ) : (
            <div>
              {!checkout ? (
                <IonImg
                  className={styles.cartImage}
                  src={
                    "https://www.pngall.com/wp-content/uploads/5/Empty-Red-Shopping-Cart-PNG-Free-Download.png"
                  }
                />
              ) : (
                <div>
                  <IonImg
                    className={styles.cartImage}
                    src={
                      "https://i.pinimg.com/originals/58/a6/59/58a6590300bcdebcaa97a93f742d516a.gif"
                    }
                  />
                  <h5 style={{ padding: 10, textAlign: "center" }}>
                    Your order number is 12, will be served soon
                  </h5>
                  <h5 style={{ padding: 10, textAlign: "center" }}>
                    Order Status: Pending
                  </h5>
                  <h6 style={{ padding: 10, textAlign: "center" }}>
                    Time Remaining: {minutes}:{seconds < 10 ? "0" : ""}
                    {seconds}
                  </h6>
                </div>
              )}
            </div>
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
}

export default Example;

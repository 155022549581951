import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import styles from "./cardContainer.module.css";
import addBtn from "../../assets/images/addBtn.png";
import addBtnDisable from "../../assets/images/addBtnDisable.png";
import animationData from "../../animation/ButtonAnimation.json";
import CartContext from "../../context/cartContext";

const Card = ({ data, gradient }: any) => {
  let [getAddon, setAddon] = useState<any>([]);
  let [getFlavour, setFlavour] = useState<any>([]);
  const [animation, setAnimation] = useState(false);
  const { addItem, items, setShowItems, allMenu, resturant } =
    useContext(CartContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (animation) {
      setTimeout(() => setAnimation(false), 2000);
    }
  }, [animation]);

  function addItemToCart(
    cart: any,
    items: any,
    itemName: any,
    selectedAddons: any,
    selectedFlavor: any,
    selectedSize: any,
    quantity: any
  ) {
    let tempCart = cart;
    // Search for an existing item in the cart that matches the selected addons, flavor, and size

    // const existingItem = undefined;
    const existingItem = cart.find((item: any) => {
      return (
        item.title === itemName &&
        item.size?.description?.toLowerCase() === getFlavour[0]?.description &&
        getAddon?.name?.toLowerCase() === item.addons[0]?.name?.toLowerCase()
      );
    });

    // If an existing item was found, increment its quantity
    if (existingItem) {
      setAnimation(true);

      existingItem.quantity += quantity;
      // addItem({
      //   item: cart,
      // });
      setAddon("");
      setFlavour([]);
    } else {
      // Search for the item in the list of all items
      const selectedItem = items.find(
        (item: any) => item?.title?.toLowerCase() === itemName?.toLowerCase()
      );

      if (selectedItem) {
        // Find the selected size object
        const selectedSizeObject = selectedItem.price[0]?.description
          ? getFlavour[0]
          : selectedItem.price[0];
        setAnimation(true);
        // Add the new item to the cart
        // setItemNum(itemNum + 1);
        tempCart.push({
          title: itemName,
          addons: getAddon === "" ? [] : getAddon,
          flavour: [],
          size: selectedSizeObject,
          quantity: quantity,
          image: selectedItem?.image,
          // user: username ? username : "owner",
          // serialNo: itemNum + 1,
        });
        // addItem({
        //   item: tempCart,
        // });

        setAddon([]);
        setFlavour([]);
      }
    }
  }
  const addToCart = (
    name: any,
    flavour: any,
    addons: any,
    quantity: any,
    cart: any
  ) => {
    //1st of all, check if the item exists
    let orderedItem = allMenu.filter(
      (items) => items.title.toLowerCase() == name.toLowerCase()
    );
    if (orderedItem.length > 0) {
      //if array length is equal to 1, it means item exist
      //now check if the item exists in cart or not
      let exist = cart.findIndex(
        (data: any) =>
          data.itemName.toLowerCase() == name.toLowerCase() &&
          data?.flavour?.description?.toLowerCase() == flavour?.toLowerCase() &&
          data?.addons?.name?.toLowerCase() == addons?.toLowerCase()
      );
      if (exist !== -1) {
        // If an existing item was found, increment its quantity
        cart[exist].quantity += parseInt(quantity);
        addItem({ items: cart });
      } else {
        const selectedItem = allMenu.find(
          (item: any) => item?.title?.toLowerCase() === name?.toLowerCase()
        );
        let selectedFlavour = selectedItem?.price.find(
          (item: any) =>
            item?.description?.toLowerCase() == flavour?.toLowerCase()
        );
        let selectedAddon = selectedItem?.addOns.find(
          (item: any) => item?.name?.toLowerCase() == addons?.toLowerCase()
        );

        let totalPrice =
          parseFloat(selectedFlavour?.price) +
          parseFloat(selectedAddon?.price ?? 0);

        addItem({
          items: [
            ...items,
            {
              itemName: name,
              flavour: selectedFlavour ? selectedFlavour : undefined,
              addons: selectedAddon ? selectedAddon : undefined,
              quantity: parseInt(quantity),
              image: selectedItem.image,
              price: totalPrice,
            },
          ],
        });
      }
    } else {
      console.log("data==>", "not exist");
    }
  };

  return (
    <div className={data.inStock ? styles.cards : styles.cardsDisable}>
      <div
        onClick={() => setShowItems([data?.title])}
        className={styles.cartDesign}
        style={{
          ...gradient,
        }}
      >
        <div className={styles.cardHeader}>
          <h1></h1>
          {/* <h6 className="cardCategory">Food</h6> */}
          <h6
            className={
              data.inStock ? styles.cardStockAvailable : styles.cardStock
            }
          >
            {data.inStock ? "Available" : "Unavailable"}
          </h6>
        </div>
        <div>
          <h6 className={styles.cardPrice}>
            {/* {resturant?.currencySymbol} */}
            {resturant?.resturant?.currencySymbol} {data.price[0].price}
          </h6>
        </div>
      </div>
      {animation ? (
        <div className={styles.addBtn}></div>
      ) : (
        <img
          // onClick={() =>
          //   addItem({
          //     items: [
          //       ...items,
          //       {
          //         itemName: data?.title,
          //         quantity: 1,
          //       },
          //     ],
          //   })
          // }

          // name: any,
          // flavour: any,
          // addons: any,
          // quantity: any,
          // cart: any
          onClick={() =>
            !data.inStock
              ? console.log("")
              : data.addOns.length === 0 &&
                data.price[0]?.description === undefined
              ? addToCart(data?.title, undefined, undefined, 1, items)
              : setShowItems([data?.title])
          }
          className={styles.addBtnImage}
          src={data.inStock ? addBtn : addBtnDisable}
        />
      )}
      <h6 className={styles.itemTitle}>{data?.title}</h6>
    </div>
  );
};

export default Card;

import { AnyTxtRecord } from "dns";
import React, { createContext } from "react";

interface ContextType {
  items: any[];
  categories: any;
  menu: any[];
  addItem: React.Dispatch<React.SetStateAction<any>>;
  handleQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCategories: React.Dispatch<React.SetStateAction<any>>;
  setMenu: React.Dispatch<React.SetStateAction<any>>;
  resturantFlag: string;
  setResturantFlag: React.Dispatch<React.SetStateAction<any>>;
  path: any;
  setPath: React.Dispatch<React.SetStateAction<any>>;
  allMenu: any[];
  setAllMenu: React.Dispatch<React.SetStateAction<any>>;
  goBack: false;
  setGoBack: React.Dispatch<React.SetStateAction<any>>;
  resturant: any;
  setResturant: React.Dispatch<React.SetStateAction<any>>;
  checkout: boolean;
  setCheckout: React.Dispatch<React.SetStateAction<any>>;
  isCartVisible: boolean;
  handleCart: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  showItems: any[];
  setShowItems: React.Dispatch<React.SetStateAction<any>>;
  onScroll: any;
  handleOnScroll: React.Dispatch<React.SetStateAction<any>>;
  isDarkTheme: boolean;
  setDarkTheme: React.Dispatch<React.SetStateAction<any>>;
}

const CartContext = createContext<ContextType>({
  items: [],
  addItem: (item: any) => {},
  handleQuantity: (item: any) => {},
  setCategories: (item: any) => {},
  categories: {},
  menu: [],
  setMenu: (item: any) => {},
  resturantFlag: "",
  setResturantFlag: (item: any) => {},
  path: {},
  setPath: (item: any) => {},
  allMenu: [],
  setAllMenu: (item: any) => {},
  goBack: false,
  setGoBack: () => {},
  resturant: {},
  setResturant: () => {},
  checkout: false,
  setCheckout: () => {},
  isCartVisible: false,
  handleCart: () => {},
  loading: false,
  setLoading: () => {},
  showItems: [],
  setShowItems: () => {},
  onScroll: "",
  handleOnScroll: (item: any) => {},
  isDarkTheme: false,
  setDarkTheme: () => {},
});

export default CartContext;

import { IonPage, IonContent } from "@ionic/react";
import "./NotFound.css";

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-text-center" fullscreen>
        <div className="centered">
          <h1>404</h1>
          <h1>Resturant Not Found</h1>
          <p>Sorry, the resturant you are looking for cannot be found.</p>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default NotFoundPage;

import { useContext, useEffect, useRef, useState } from "react";
import {
  createAnimation,
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonList,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonItem,
} from "@ionic/react";
import CartContext from "../../context/cartContext";
import styles from "./style.module.css";
import ApiConfig from "../../config/apiConfig";
import animationData from "../../animation/added-item.json";
import noImage from "../../assets/images/noimage.jpeg";

function ItemModal() {
  const modal = useRef<HTMLIonModalElement>(null);
  const [filtered, setFiltered] = useState<any>([]);
  const { allMenu, resturant, showItems, items, setShowItems, addItem } =
    useContext(CartContext);
  let [getAddon, setAddon] = useState<any>("");
  let [getFlavour, setFlavour] = useState<any>([]);
  let [showAnimation, setShowAnimation] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // const addToCart = (data: any) => {
  //   console.log("data", data);
  //   setShowAnimation(true);
  //   addItem({
  //     items: [
  //       ...items,
  //       {
  //         itemName: data?.title,
  //         quantity: 1,
  //       },
  //     ],
  //   });
  //   setShowAnimation(false);
  //   setTimeout(() => dismiss(), 2000);
  // };

  const addToCart = (
    name: any,
    flavour: any,
    addons: any,
    quantity: any,
    cart: any
  ) => {
    //1st of all, check if the item exists
    let orderedItem = allMenu.filter(
      (items) => items.title.toLowerCase() == name.toLowerCase()
    );
    if (orderedItem.length > 0) {
      //if array length is equal to 1, it means item exist
      //now check if the item exists in cart or not
      let exist = cart.findIndex(
        (data: any) =>
          data.itemName.toLowerCase() == name.toLowerCase() &&
          data?.flavour?.description?.toLowerCase() == flavour?.toLowerCase() &&
          data?.addons?.name?.toLowerCase() == addons?.toLowerCase()
      );

      if (exist !== -1) {
        // If an existing item was found, increment its quantity
        cart[exist].quantity += parseInt(quantity);
        addItem({ items: cart });
      } else {
        const selectedItem = allMenu.find(
          (item: any) => item?.title?.toLowerCase() === name?.toLowerCase()
        );
        let selectedFlavour = selectedItem?.price.find(
          (item: any) =>
            item?.description?.toLowerCase() == flavour?.toLowerCase()
        );
        let selectedAddon = selectedItem?.addOns.find(
          (item: any) => item?.name?.toLowerCase() == addons?.toLowerCase()
        );

        let totalPrice =
          parseFloat(selectedFlavour?.price) +
          parseFloat(selectedAddon?.price ?? 0);

        addItem({
          items: [
            ...items,
            {
              itemName: name,
              flavour: selectedFlavour ? selectedFlavour : undefined,
              addons: selectedAddon ? selectedAddon : undefined,
              quantity: parseInt(quantity),
              image: selectedItem.image,
              price: totalPrice,
            },
          ],
        });
      }

      setTimeout(() => dismiss(), 500);
    } else {
      console.log("data==>", "not exist");
    }
  };

  const contentRef: any = useRef(null);

  const scrollToTop = () => {
    contentRef.current.scrollToTop();
  };
  const scrollToBottom = () => {
    contentRef.current.scrollToBottom();
  };

  const handleAddon = (event: any) => {
    // if (event.detail.value == undefined) {
    //   setAddon("");
    // } else if (event.detail.value == null) {
    //   setAddon("");
    // } else {
    //   setAddon(event.detail.value);
    // }
    setAddon(event.detail.value);
  };
  const handleFlavour = (event: any) => {
    if (event.detail.value == undefined) {
      setFlavour([]);
    } else if (event.detail.value == null) {
      setFlavour([]);
    } else {
      setFlavour([event.detail.value]);
    }
  };

  useEffect(() => {
    if (showItems?.length > 0) {
      let tempVar: any[] = [];
      showItems?.forEach((data: any, ind: any) => {
        let filter: any = allMenu.find((item: any, i) => {
          return item.title.toLowerCase() === data.toLowerCase();
        });
        tempVar.push(filter);
      });
      setFiltered(tempVar);
    }
  }, [showItems, allMenu]);

  let _showItems = showItems?.length > 0 ? true : false;

  function dismiss() {
    modal.current?.dismiss();
  }
  function showModal() {
    modal.current?.present();
    setAddon("");
    setFlavour([]);
  }
  useEffect(() => {
    if (_showItems) {
      showModal();
      setShowAnimation(true);
    } else if (!_showItems) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
      setTimeout(() => {
        dismiss();
      }, 2000);
    }
  }, [showItems]);

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };
  const handleModalDismiss = () => {
    console.log("Modal dismissed");
    setShowItems([]);
  };

  return (
    <>
      <IonContent className="ion-padding">
        <IonModal
          className={styles.modalCenter}
          id="example-modal"
          ref={modal}
          trigger="open-modal"
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
        >
          <IonContent ref={contentRef} scrollEvents={true}>
            <IonToolbar>
              <IonTitle>DETAILS</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => dismiss()}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
            <IonList>
              {filtered.length !== 0
                ? filtered.map((obj: any, ind: number) => {
                    // if (!obj.inStock) {
                    //   setTimeout(() => dismiss(), 3000);
                    // }
                    return (
                      <IonGrid>
                        <div key={ind} className={styles.itemModalContainer}>
                          <IonRow>
                            <IonCol
                              sizeSm="12"
                              sizeMd="6"
                              sizeLg="6"
                              sizeXl="6"
                              sizeXs="12"
                            >
                              <img
                                className={styles.imageStyle}
                                src={
                                  obj?.image
                                    ? `${ApiConfig.SERVER_URL}${obj?.image}`
                                    : noImage
                                }
                              />
                              <p className={styles.inStockBadge}>
                                {obj.inStock ? "Available" : "Unavailable"}
                              </p>
                            </IonCol>
                            <IonCol
                              sizeXs="12"
                              sizeMd="6"
                              // sizeSm="4"
                              sizeSm="12"
                              sizeLg="6"
                              sizeXl="6"
                            >
                              <div className={styles.moalDataContainer}>
                                <IonLabel>
                                  <h2 className={styles.itemTitleModal}>
                                    {obj?.title}
                                  </h2>
                                  <p className={styles.itemModalDesc}>
                                    {obj?.description}
                                  </p>
                                </IonLabel>

                                {obj?.price.length > 0 ? (
                                  <IonLabel>
                                    <IonRadioGroup
                                      className={styles.radiobtn}
                                      onIonChange={handleFlavour}
                                      allowEmptySelection={false}
                                      value={getFlavour[0]}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          paddingBottom: "6px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <h2 className={styles.itemModalHeading}>
                                          Flavour:
                                        </h2>
                                        <div className={styles.required}>
                                          <p>Required</p>
                                        </div>
                                      </div>
                                      {obj?.price.map(
                                        (data: any, i: number) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                margin: "0px -6px",
                                              }}
                                              key={i}
                                            >
                                              {data?.description ? (
                                                <IonItem
                                                  className={styles.customItem}
                                                >
                                                  <p
                                                    className={styles.radioItem}
                                                  >
                                                    {data?.description}
                                                  </p>
                                                  <IonRadio
                                                    className={styles.small}
                                                    slot="start"
                                                    value={data}
                                                  ></IonRadio>
                                                </IonItem>
                                              ) : (
                                                <p>Regular</p>
                                              )}

                                              <p
                                                className={
                                                  styles.modalItemPrice
                                                }
                                              >
                                                {
                                                  resturant?.resturant
                                                    ?.currencySymbol
                                                }

                                                {data?.price}
                                              </p>
                                            </div>
                                          );
                                        }
                                      )}
                                    </IonRadioGroup>
                                    <p></p>
                                    {obj?.addOns.length > 0 ? (
                                      <IonLabel>
                                        <IonRadioGroup
                                          onIonChange={(e) => handleAddon(e)}
                                          allowEmptySelection={true}
                                          value={getAddon}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                              paddingBottom: "6px",
                                            }}
                                          >
                                            <h2
                                              className={
                                                styles.itemModalHeading
                                              }
                                            >
                                              AddOns:
                                            </h2>
                                            <div className={styles.optional}>
                                              <p>Optional </p>
                                            </div>
                                          </div>
                                          {obj?.addOns.map(
                                            (data: any, i: number) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                    alignSelf: "center",
                                                    margin: "0px -6px",
                                                  }}
                                                  key={i}
                                                >
                                                  <IonItem
                                                    className={
                                                      styles.customItem
                                                    }
                                                  >
                                                    <IonRadio
                                                      className={styles.small}
                                                      slot="start"
                                                      value={data}
                                                    ></IonRadio>
                                                    <p
                                                      className={
                                                        styles.radioItem
                                                      }
                                                    >
                                                      {data.name}
                                                    </p>
                                                  </IonItem>
                                                  <p
                                                    className={
                                                      styles.modalItemPrice
                                                    }
                                                  >
                                                    {
                                                      resturant?.resturant
                                                        ?.currencySymbol
                                                    }

                                                    {data.price}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                          <p></p>
                                        </IonRadioGroup>
                                      </IonLabel>
                                    ) : null}

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        paddingTop: "15px",
                                      }}
                                    >
                                      <IonButton
                                        onClick={() => dismiss()}
                                        color="light"
                                        shape="round"
                                        style={{ marginRight: "8px" }}
                                      >
                                        Cancel
                                      </IonButton>
                                      <IonButton
                                        onClick={() =>
                                          addToCart(
                                            obj.title,
                                            getFlavour[0]?.description,
                                            getAddon?.name,
                                            1,
                                            items
                                          )
                                        }
                                        disabled={
                                          !obj.inStock ||
                                          (obj.price.length > 1 &&
                                            getFlavour.length < 1 &&
                                            obj.price[0].description !==
                                              undefined)
                                            ? true
                                            : false
                                        }
                                        shape="round"
                                        className={styles.addBtnn}
                                      >
                                        Add to Cart
                                      </IonButton>
                                    </div>
                                  </IonLabel>
                                ) : null}
                              </div>
                            </IonCol>
                          </IonRow>
                        </div>
                      </IonGrid>
                    );
                  })
                : null}
            </IonList>
          </IonContent>
        </IonModal>
      </IonContent>
    </>
  );
}

export default ItemModal;

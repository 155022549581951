import { IonButton, IonImg, IonLabel, IonList, IonText } from "@ionic/react";
import CartCard from "../CartCard";
import { IonIcon } from "@ionic/react";
import { cart } from "ionicons/icons";

import { trashBin } from "ionicons/icons";
import styles from "./cart.module.css";
import { useContext, useEffect, useState } from "react";
import CartContext from "../../context/cartContext";

const Cart = () => {
  const [countDownTime, setCountDownTime] = useState(20 * 60 * 1000);
  const { items, resturant, checkout, setCheckout, addItem } =
    useContext(CartContext);

  const emptyCart = () => {
    addItem({
      items: [],
    });
  };

  useEffect(() => {
    if (checkout) {
      // Update the countdown every second
      const countdownInterval = setInterval(() => {
        // Subtract one second from the countdown time
        setCountDownTime((prevTime) => prevTime - 1000);
      }, 1000);

      // Clear the interval when the component unmounts
      return () => clearInterval(countdownInterval);
    }
  }, [checkout]);

  const minutes: any = Math.floor(countDownTime / 60000);
  const seconds: any = ((countDownTime % 60000) / 1000).toFixed(0);

  let totalItems = items.length;
  let combinedQuantity = items.reduce(
    (total, order) => total + order.quantity,
    0
  );
  const totalAmount = items
    .reduce((totalPrice, order) => totalPrice + order.quantity * order.price, 0)
    .toFixed(2);

  return (
    <div className={styles.mainOuterCard}>
      <div className={styles.mainCart}>
        <h1 className={styles.cartLabel}>Cart</h1>
        <IonIcon
          // onClick={() => addItem({ item: [] })}
          slot="start"
          icon={cart}
          className={styles.cartIcon}
        ></IonIcon>
        <p className={styles.badgeCount}>{items?.length}</p>
      </div>

      <IonList className={styles.ListBg}>
        <div>
          {/* Map over the userArrays array */}
          {items.map((item: any, index: any) => {
            return (
              <CartCard
                data={item}
                name={item?.itemName}
                image={item?.image}
                price={item.price}
                quantity={item?.quantity}
                selectedAdone={item?.addons?.name}
                selectedSize={item?.flavour?.description}
                user={item?.user}
                serialNo={item?.serialNo}
              />
            );
          })}
        </div>
      </IonList>
      {items.length > 0 ? (
        <div>
          <div className={styles.detailsCard}>
            <div className={styles.detailsItem}>
              <IonLabel>Subtotal</IonLabel>
              <IonLabel>
                {resturant?.resturant?.currencySymbol}
                {totalAmount}
              </IonLabel>
            </div>
            <div className={styles.detailsItem}>
              <IonLabel>Discount sales</IonLabel>
              <IonLabel>{resturant?.resturant?.currencySymbol} 0</IonLabel>
            </div>
            <div className={styles.detailsItem}>
              <IonLabel>Total sales tax</IonLabel>
              <IonLabel>{resturant?.resturant?.currencySymbol} 0</IonLabel>
            </div>
            <div className={styles.detailsItem}>
              <h4>Total</h4>
              <h4>
                {resturant?.resturant?.currencySymbol}
                {totalAmount}
              </h4>
            </div>
          </div>
          <p style={{ padding: 10, textAlign: "center" }}>
            Your cart currently includes {items?.length}{" "}
            {totalItems > 1 ? `items` : `item`}, with a combined quantity of{" "}
            {combinedQuantity} and a total cost of{" "}
            {resturant?.resturant?.currencySymbol} {totalAmount}
          </p>
          <div className={styles.btnDiv}>
            <IonButton color="light" shape="round" onClick={emptyCart}>
              Cancel
            </IonButton>

            <IonButton
              shape="round"
              className={styles.addBtnn}
              onClick={() => {
                if (items.length > 0) {
                  setCheckout(true);
                }
              }}
              id="example-modal"
              // className="confirmBtn"
              // color="danger"
            >
              Confirm Order
            </IonButton>
          </div>
        </div>
      ) : (
        <div>
          {!checkout ? (
            <IonImg
              className={styles.cartImage}
              src={
                "https://www.pngall.com/wp-content/uploads/5/Empty-Red-Shopping-Cart-PNG-Free-Download.png"
              }
            />
          ) : (
            <div>
              <IonImg
                className={styles.cartImage}
                src={
                  "https://i.pinimg.com/originals/58/a6/59/58a6590300bcdebcaa97a93f742d516a.gif"
                }
              />
              <h5 style={{ padding: 10, textAlign: "center" }}>
                Your order number is 12, will be served soon
              </h5>
              <h5 style={{ padding: 10, textAlign: "center" }}>
                Order Status: Pending
              </h5>
              <h6 style={{ padding: 10, textAlign: "center" }}>
                Time Remaining: {minutes}:{seconds < 10 ? "0" : ""}
                {seconds}
              </h6>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;

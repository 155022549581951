import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import styles from "./webModal.module.css";

const MobileWebModal: React.FC = () => {
  return (
    <div className={styles.centeredMobileModal}>
      {/* Add your content here */}
      <h1>Thank You</h1>
      <p>for choosing MenuNext</p>
      <a href="#">
        <p>
          Please Click to download our Mobile Application for better experience
        </p>
      </a>
    </div>
  );
};

export default MobileWebModal;

import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import { SplashScreen } from "@capacitor/splash-screen";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Routes from "./Routes";
import CartProvider from "./context/cartProvider";
import { useEffect } from "react";
import { StatusBar, Style } from "@capacitor/status-bar";

setupIonicReact();

const App: React.FC = () => {
  const showSplash = async () => {
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });
  };
  window.addEventListener("statusTap", function () {
    console.log("statusbar tapped");
  });
  const showStatusBar = async () => {
    await StatusBar.show();
  };

  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };
  setStatusBarStyleDark();
  useEffect(() => {
    showSplash();
  }, []);

  return (
    <CartProvider>
      <IonApp>
        <Routes />
      </IonApp>
    </CartProvider>
  );
};
export default App;

import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import { useContext, useState } from "react";
import styles from "./cardContainer.module.css";
import noimage from "../../assets/images/noimage.jpeg";
import Card from ".";
import CartContext from "../../context/cartContext";
import ApiConfig from "../../config/apiConfig";

const CardsContainer = () => {
  const { menu } = useContext(CartContext);
  let loading: any = false;

  return (
    <IonGrid>
      {loading ? (
        <div className={styles.spinnerclass}>
          <IonSpinner name="circles"></IonSpinner>
        </div>
      ) : (
        <IonRow>
          {menu.length > 0 ? (
            menu.map((data: any, index: any) => {
              const gradient = {
                background: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8)), url(${
                  data?.image
                    ? `${ApiConfig.SERVER_URL}${data?.image}`
                    : `${noimage}`
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                Position: "relative",
                width: "100%",
                height: "200px",
                borderRadius: "8px",
                justifyContent: "space-between",
                paddingLeft: "8px",
                paddingRight: "10px",
              };
              return (
                <IonCol
                  key={index}
                  size="12"
                  size-lg="4"
                  size-md="6"
                  size-sm="6"
                >
                  <Card data={data} gradient={gradient} />
                </IonCol>
              );
            })
          ) : (
            <h3>No Item Found</h3>
          )}
        </IonRow>
      )}
    </IonGrid>
  );
};

export default CardsContainer;

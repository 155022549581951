import { IonGrid, IonRow, IonSpinner, IonText, IonToggle } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import Card from "../Card";
import CategoryCard from "../categoryCard";
import styles from "./categoryContainer.module.css";
import { IonIcon } from "@ionic/react";
import { arrowBack, chevronForward } from "ionicons/icons";
import { IonBreadcrumb, IonBreadcrumbs } from "@ionic/react";
import CardsContainer from "../Card/CardsContainer";
import CartContext from "../../context/cartContext";

const CategoryContainer = () => {
  const myDivRef: any = useRef(null);
  let isFirstRender: any = false;
  const {
    categories,
    path,
    allMenu,
    setMenu,
    setCategories,
    setPath,
    goBack,
    setGoBack,
    loading,
    showItems,
    onScroll,
    handleOnScroll,
    setDarkTheme,
  } = useContext(CartContext);

  // <theme>
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkTheme(prefersDark.matches);

    const handleThemeChange = (e: any) => {
      setIsDarkTheme(e.matches);
    };

    prefersDark.addEventListener("change", handleThemeChange);

    return () => {
      prefersDark.removeEventListener("change", handleThemeChange);
    };
  }, []);

  const handleThemeToggle = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  useEffect(() => {
    if (isDarkTheme) {
      document.body.classList.add("dark");
      setDarkTheme(true);
    } else {
      document.body.classList.remove("dark");
      setDarkTheme(false);
    }
  }, [isDarkTheme]);
  // <theme />

  const handleScroll = (scrollOffset: any) => {
    myDivRef.current.scrollBy({
      top: scrollOffset,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (showItems.length < 1) {
      if (onScroll === "up") {
        handleScroll(-200);
      }
      if (onScroll === "down") {
        handleScroll(200);
      }
      handleOnScroll("");
    }
  }, [onScroll]);

  let [breadCrumbs, setBreadCrumbs] = useState(["Main Categories"]);
  useEffect(() => {
    if (!path) {
      setBreadCrumbs(["Main Categories"]);
    }
    if (path.selectedCategory?.name && !path.selectedSubCategory?.name) {
      setBreadCrumbs(["Main Categories", path.selectedCategory?.name]);

      //iski sub find kr k sub me rkhwani
      const backupCategories = categories;
      let tempSub = categories?.allCategories.filter(
        (e: any) => e?.parent == path.selectedCategory?._id
      );
      setCategories({
        allCategories: backupCategories.allCategories,
        mainCategories: backupCategories.mainCategories,
        subCategories: tempSub,
      });
    }
    if (path.selectedCategory?.name && path.selectedSubCategory?.name) {
      let tempMenu = allMenu.filter(
        (e: any) => e?.category === path.selectedSubCategory?._id
      );
      setMenu({
        item: tempMenu,
      });

      setBreadCrumbs([
        "Main Categories",
        path.selectedCategory?.name,
        path.selectedSubCategory?.name,
      ]);
    }
  }, [path]);

  useEffect(() => {
    if (goBack) {
      if (breadCrumbs.length == 3) {
        const backupCategories = categories;
        const tempMainCat = path.selectedCategory;
        let tempSub = categories?.subCategories.filter(
          (e: any) => e?.parent == tempMainCat._id
        );
        setPath({
          selectedCategory: tempMainCat,
          selectedSubCategory: "",
        });
        setCategories({
          allCategories: backupCategories.allCategories,
          mainCategories: backupCategories.mainCategories,
          subCategories: tempSub,
        });
        setMenu(allMenu);
      }
      if (breadCrumbs.length == 2) {
        setPath({});
        setMenu({ item: allMenu });
        setBreadCrumbs(["Main Categories"]);
        const mainCategories: any = [];
        const subCategories: any = [];
        let allCategoryBackup = categories?.allCategories;
        categories?.allCategories.forEach((category: any) => {
          if (category.parent) {
            subCategories.push(category);
          } else {
            mainCategories.push(category);
          }
        });

        setCategories({
          allCategories: allCategoryBackup,
          mainCategories,
          subCategories,
        });
      }
    }
    setGoBack(false);
  }, [goBack]);

  return (
    <div className={styles.categoryContainer}>
      <div>
        <div className={styles.categoryContainerInnerDiv}>
          <div className={styles.navContainer}>
            <div className={styles.breadCrmContainer}>
              <IonBreadcrumbs>
                {breadCrumbs.map((obj, i) => {
                  return (
                    <IonBreadcrumb
                      color="light"
                      onClick={() => {
                        if (obj === "Main Categories") {
                          // setSelectedRoute({ item: "MainCategories" });
                          setPath({});
                          setBreadCrumbs(["Main Categories"]);
                          setMenu({ item: allMenu });
                          const mainCategories: any = [];
                          const subCategories: any = [];
                          let allCategoryBackup = categories?.allCategories;
                          categories?.allCategories.forEach((category: any) => {
                            if (category.parent) {
                              subCategories.push(category);
                            } else {
                              mainCategories.push(category);
                            }
                          });

                          setCategories({
                            allCategories: allCategoryBackup,
                            mainCategories,
                            subCategories,
                          });
                        } else if (i !== 2) {
                          setBreadCrumbs([
                            "Main Categories",
                            path.selectedCategory?.name,
                          ]);

                          if (path.selectedSubCategory == "noSub") {
                            console.log("no sub found");
                            return;
                            //   setPath({ selectedCategory: path.selectedCategory });
                            // setMenu({ item: allMenu });
                          }
                          setPath({ selectedCategory: path.selectedCategory });
                          setMenu({ item: allMenu });
                        }
                      }}
                      key={i}
                    >
                      <p className={styles.breadcrm}>{obj}</p>

                      <IonIcon
                        slot="separator"
                        icon={chevronForward}
                        color="white"
                        size="small"
                      ></IonIcon>
                    </IonBreadcrumb>
                  );
                })}
              </IonBreadcrumbs>
            </div>
            <IonToggle
              className={styles.toggleButton}
              id="themeToggle"
              slot="end"
              checked={isDarkTheme}
              onIonChange={handleThemeToggle}
            ></IonToggle>
          </div>
          {isFirstRender ? (
            <div>
              <h6 className={styles.selectOrder}>Select Order</h6>
            </div>
          ) : (
            <div className={styles.navContainerMobile}>
              {breadCrumbs.length == 1 ? null : (
                <IonIcon
                  onClick={() => setGoBack(true)}
                  className={styles.backIcon}
                  icon={arrowBack}
                />
              )}
              <h6
                style={{ paddingLeft: "10px" }}
                className={styles.mobileCategory}
              >
                {breadCrumbs.length == 1
                  ? "Main Category"
                  : breadCrumbs.length == 2
                  ? breadCrumbs[1]
                  : breadCrumbs[2]}
              </h6>
              <IonToggle
                className={styles.toggleButton}
                id="themeToggle"
                slot="end"
                checked={isDarkTheme}
                onIonChange={handleThemeToggle}
              ></IonToggle>
            </div>
          )}
          <IonGrid>
            <IonRow
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                margin: "0 auto",
              }}
            ></IonRow>
            <IonRow ref={myDivRef} className={styles.categoryCardContainer}>
              {loading ? (
                <div className={styles.spinnerclass}>
                  <IonSpinner name="circles"></IonSpinner>
                </div>
              ) : path.selectedSubCategory ? (
                <CardsContainer />
              ) : categories.mainCategories ? (
                <CategoryCard
                  categoryData={
                    !path.selectedCategory
                      ? categories.mainCategories
                      : path.selectedCategory
                      ? categories.subCategories
                      : categories.mainCategories
                  }
                />
              ) : (
                <div className={styles.spinnerclass}>
                  <IonSpinner name="circles"></IonSpinner>
                </div>
              )}
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </div>
  );
};

export default CategoryContainer;
